<template>
	<div class="">
		<div class="page-content" style="margin-top: 40px;">
			<div style="display: flex;">
					<div style="border: solid 1px rgb(24 48 91);min-height: 600px;padding: 20px;width: 75%;margin-right: 2%;">
						<div style="text-align: center;font-size: 22px;color: rgb(24 48 91);;font-weight: 700;">
							{{articleDetails.fieldA}}
						</div>
						<div style="color: #8b8888;text-align: center;">
							<p style=""><span style="margin-right: 20px;">来源:{{articleDetails.fieldC}}</span><span style="">发布时间:{{articleDetails.createDate}}</span></p>
						</div>
						<div style="text-align: justify;">
							<p v-html="articleDetails.fieldI"></p>
						</div>
					</div>
					<div style="width: 25%;">
						<div>
							<p style="font-weight: bold;margin: 20px;">◆ 热门会议</p>
							<div style="display: flex;margin-top: 40px;min-height: 40px;">
								<div class="num" style="background-color: #e60012;">1</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://iced.ac.cn"  class="two">
										第五届环境与灾害国际学术会议（ICED2021）</el-link>
								</div>
							</div>
							<div style="display: flex;margin-top: 20px;min-height: 40px;">
								<div class="num">2</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://icbpconf.org" target="view_window" class="two">
										第九届生物医药与制药学国际学术会议（ICBP2021）</el-link>
								</div>
							</div>
							<div style="display: flex;margin-top: 20px;min-height: 40px;">
								<div class="num" style="background-color: #178d75;">3</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://icamis.iamset.org" target="view_window" class="two">第十一届机械科学与工程国际学术会议
									</el-link>
								</div>
							</div>
							<div style="display: flex;margin-top: 20px;min-height: 40px;">
								<div class="num" style="background-color: #7d7d7d;">4</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://icpoeconf.org" target="view_window" class="two">第五届港口与海洋工程国际学术会议
									</el-link>
								</div>
							</div>
							<div style="display: flex;margin-top: 20px;min-height: 40px;">
								<div class="num" style="background-color: #7d7d7d;;">5</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://isbr.asiasame.org" target="view_window" class="two">
										第三届生物质资源化再利用国际学术会议</el-link>
								</div>
							</div>
							<div style="display: flex;margin-top: 20px;min-height: 40px;">
								<div class="num" style="background-color: #7d7d7d;">6</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" href="http://hcrconf.conferences.ac.cn" target="view_window" class="two">
										2019知名学术期刊主编与高被引科学家学术论坛</el-link>
								</div>
							</div>
							<el-link :underline="false" href="/meeting" style="float: right;margin-top: 40px;">查看更多
							</el-link>
						</div>
					</div>
			</div>
			
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				articleDetails:{},
			};
		},

		methods: {
			
		},
		created() {
			this.articleDetails = this.$route.params
		},
		mounted() {
			
		},
		
	};
</script>

<style lang="scss" scoped>
	.num {
		float: left;
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-color: #ff5722;
		// background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%);
		background-blend-mode: normal, normal;
	}
	
</style>
